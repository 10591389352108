<template>
  <Dialog :close-on-outside-click="false" :show-close-button="false">
    <ResetLegacyPasswordForm
      v-if="!success"
      :hash="data.legacyHash"
      @success="() => (success = true)"
    />
    <SuccessView
      v-else
      headline="resetLegacyPassword.success.headline"
      sub-headline="resetLegacyPassword.success.subHeadline"
      :texts="['resetLegacyPassword.success.text1']"
      image="@/assets/images/minilu/minilu-shield-success.svg"
      cta="resetLegacyPassword.success.cta"
      has-close
      @click-cta="
        () => dialogStore.openDialog(DialogIdent.LOGIN, { email: data.email })
      "
      @close="dialogStore.closeDialog()"
    />
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/global/dialogFrame.vue';
import ResetLegacyPasswordForm from '~/components/form/resetLegacyPasswordForm/minilu/resetLegacyPasswordForm.vue';
import { DialogIdent, useDialogStore } from '~/stores/useDialogStore';
import SuccessView from '~/components/formFields/components/successView/minilu/successView.vue';

const dialogStore = useDialogStore();
dialogStore.setHeadline('resetLegacyPassword.headline');

const data = dialogStore.getDialogData<{ legacyHash: string; email?: string }>(
  DialogIdent.RESET_LEGACY_PASSWORD,
);

const success = ref(false);
</script>
